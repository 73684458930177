import React from 'react';
import './AboutUs.css';  // Optional for styling
import KaranPhoto from './KaranPhoto.png';
import { FaLinkedin } from 'react-icons/fa';
const AboutUs = () => {
  return (
    <div className="about-us-container">
      <section className="hero-section">
        <h1>About Bazzuka AI</h1>
        <p>
          At Bazzuka AI, we’re dedicated to transforming how businesses handle customer interactions by creating AI Voice Agents that sound human and provide efficient, natural, and engaging conversations. Our mission is to enhance customer satisfaction and reduce the workload of human agents by automating routine tasks, allowing companies to focus on what matters most—growth and innovation.
        </p>
      </section>

      <section className="vision-mission">
        <h2>Our Vision</h2>
        <p>
          We envision a world where AI seamlessly integrates into customer communication, helping businesses deliver top-notch service 24/7 without compromising on quality. The goal is to not only provide businesses with AI Voice Agents, but ultimately provide a platform that handles all of their communication channels with in-depth analysis about their customers.
        </p>

      </section>


      {/* <section className="values">
        <h2>Our Core Values</h2>
        <ul>
          <li><strong>Innovation:</strong> Continuously pushing the boundaries of what's possible with AI.</li>
          <li><strong>Customer Focus:</strong> Every feature we build is designed with customer success in mind.</li>
          <li><strong>Integrity:</strong> We build solutions that our clients can trust.</li>
        </ul>
      </section> */}

      <section className="founder">
        <h2>Meet The Founder</h2>
        <div className="founder-section">
          <div className="founder-image">
            {/* Replace the src with your actual image */}
            <img src={KaranPhoto} alt="Founder" />
            
          </div>
          <div className="founder-bio">
            <p>
              <strong>Karan Shah</strong>, the Founder and CEO of Bazzuka AI, is a Cornell University graduate (Masters in Computer Science)  with a passion for Artificial Intelligence and Entrepreneurship. With a vast experience in developing AI-powered solutions, Karan saw a unique opportunity to revolutionize customer communication through Generative AI. This led to the creation of Bazzuka AI, where the focus is on delivering intelligent, human-like interactions that help businesses automate repetitive tasks and improve customer satisfaction.
            </p>
            <a href="https://www.linkedin.com/in/karan-shah-354281211/" target="_blank" rel="noopener noreferrer" className="text-secondary mx-2 text-decoration-none">
                                <FaLinkedin size={30} />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;