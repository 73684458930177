const solutions = [
    {
      solution: "Hotels",
      title: "AI Voice Agents for Hotels",
      subheading: "Transform your hotel’s customer service with our AI Voice Agents, designed to engage guests in human-like conversations. Enhance customer satisfaction with reduced wait times, faster response and streamline interactions, delivering a superior, personalized experience that keeps guests coming back. Our AI Voice Agents are completely customizable and can be designed to interact however you want it for your custom use case. Below are a few use-cases, but we'd love to hear if you have any other use-case ideas as well.", 
      usecases: [
        {
          title: "AI Front Desk Agent",
          subheading: "The AI talks with your customers very much like a human and navigates them through the entire hotel booking process in a fast and efficient way. This not only reduces your burden to manage all these routine calls but also enhances customer satisfaction with no wait times and quicker responses.",
          videoLink: "https://www.youtube.com/embed/shUhrNq-1bc?si=fvCeRz7kxRllJauL",
        },
        {
          title: "AI Feedback Agent",
          subheading: "The AI calls your recent visitors and takes feedback about their stay at your hotel. If something went wrong it can ask deeper questions, ultimately leaving the customers heard and providing you with the overview and insights of what can be improved and what your customers have to say about you. This is a quite new and advanced technology as compared to the rule based traditional IVRs.",
          videoLink: "https://www.youtube.com/embed/6zWG1MaaJZk?si=DkZk5QLP6uFoi89w",
        },
        {
          title: "AI Booking Agent",
          subheading: "The AI talks with your customers very much like a human and navigates them through the entire hotel booking process in a fast and efficient way. This not only reduces your burden to manage all these routine calls but also enhances customer satisfaction with no wait times and quicker responses.",
          videoLink: "https://www.youtube.com/embed/WEn8XC2sNFM?si=nMOId0Y-gXBjYOCz",
        }
      ]
    },
    {
      solution: "Real Estate",
      title: "AI Voice Agents for Real Estate",
      subheading: "Real estate brokers often struggle with handling a high volume of calls from buyer agents and prospective buyers, all looking for property availability, details, and scheduling tours. Our AI Voice Agents are designed to alleviate this burden by managing these inquiries through human-like conversations, providing instant responses and scheduling tours automatically, ensuring no calls go missed. By reducing the call volume you need to handle, our solution streamlines your workflow and ensures your clients receive quick and accurate information. Fully customizable, our AI Voice Agents can be tailored to your specific needs and processes, while improving client satisfaction. Below is a demo video that walks you through the entire process, but we’re open to any custom ideas you may have.", 
      usecases: [
        {
          title: "AI Real Estate Receptionist",
          subheading: "This AI Receptionist can integrate with your existing CRM/ software and can navigate your customers through the entire process from giving all the details about the property to scheduling property tours.",
          videoLink: "https://www.youtube.com/embed/V4ziPB_o-YI?si=_FfGeuDyc3c5EtG2",
        },
      ]
    },
    
  ];
  
  export default solutions;