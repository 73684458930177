import React from 'react';
import { FaLinkedin, FaInstagram } from 'react-icons/fa'; // Importing LinkedIn and Instagram icons
import { FaYoutube } from 'react-icons/fa';


const Footer = () => {
    return (
        <footer className="footer text-white py-4 ai-gradient-left w-100 mt-5">
            <div className="container d-flex justify-content-center align-items-center">
                <div className="row w-100"> {/* Use `row` to contain the columns */}
                    
                    <div className="col-md-5 text-center"> {/* Center the content in the column */}
                        <h5 className="text-center dark-text">Get in Touch</h5>
                        <form action="https://formspree.io/f/xldrnawd" method="POST">
                            <div className="form-group m-2">
                                <input required type="text" name="text" className="form-control text-white" id="name" placeholder="Enter your name" />
                            </div>
                            <div className="form-group m-2">
                                <input required type="email" name='email' className="form-control text-white" id="email" placeholder="Enter your email" />
                            </div>
                            <div className="form-group m-2">
                                <textarea required className="form-control text-white" name="message" id="message" rows="3" placeholder="Your message"></textarea>
                            </div>
                            <button type="submit" id="submit-btn" className="btn w-100 m-2">Submit</button>
                        </form>
                    </div> 

                    <div className="col-md-3 text-center"> {/* Keep the column centered */}
                        <div className="text-start">
                            <div className="fw-bold mb-2 text-secondary">Use Cases</div>
                            <a className="text-secondary text-decoration-none" href="https://www.bazzuka.tech/?tab=Appointments">Appointment Scheduling</a><br/>
                            <a className="text-secondary text-decoration-none" href="https://www.bazzuka.tech/?tab=Customer-Service">Customer Service</a><br/>
                            <a className="text-secondary text-decoration-none" href="https://www.bazzuka.tech/?tab=Feedbacks">Feedbacks</a><br/>
                            <a className="text-secondary text-decoration-none" href="https://www.bazzuka.tech/?tab=Interviews">Job Interviews</a><br/>
                        </div>
                    </div>

                    <div className="col-md-4 text-center"> {/* Keep the column centered */}
                        <div className="text-start">
                            <div className="fw-bold fs-3 mb-2 ai-text-gradient">Bazzuka</div>
                            <p className="text-secondary">Empowering your business communications with AI Voice Agents</p>
                        </div>
                        <div className="social-icons text-center mt-3">
                            <a href="https://www.linkedin.com/company/bazzuka-ai" target="_blank" rel="noopener noreferrer" className="text-secondary mx-2 text-decoration-none">
                                <FaLinkedin size={30} />
                            </a>
                            <a href="https://www.instagram.com/bazzuka_ai/" target="_blank" rel="noopener noreferrer" className="text-secondary mx-2 text-decoration-none">
                                <FaInstagram size={30} />
                            </a>
                            <a href="https://www.youtube.com/@BazzukaAI" target="_blank" rel="noopener noreferrer" className="text-secondary mx-2 text-decoration-none">
                                <FaYoutube size={30} />
                            </a>
                        </div>
                    </div>

                </div> {/* End of row */}
            </div> {/* End of container */}
        </footer>
    );
};

export default Footer;