import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import TopStrip from './TopStrip';


function NavbarTop() {
  return (
    <div className='sticky-top'>
      <TopStrip />
      <Navbar id="navbar" data-bs-theme="dark" style={{ zIndex: 1000 }}>
        <Container>
          <Navbar.Brand href="/" className='fs-2 fw-bold' >Bazzuka</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            {/* <Nav.Link href="#pricing">Pricing</Nav.Link>  */}
            {/* <NavDropdown title="Solutions" id="basic-nav-dropdown">
              <NavDropdown.Item href="solutions/?solution=Hotels">AI Voice Agents for Hotels</NavDropdown.Item>
              <NavDropdown.Item href="solutions/?solution=Real Estate">AI Voice Agents for Real Estate</NavDropdown.Item> */}
              {/* <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item> */}
              {/* <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            {/* </NavDropdown> */}
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarTop;